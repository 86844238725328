import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { getShopMetafields } from '@/services/shop';
import { fetchProfile, getUserInfo, saveUserInfo } from '@/utils/helper';
import { getApp, makeRecaptcha } from '@/utils';

const defaultValue = {
  app: '',
  brand: '',
  shopify: {},
  userInfo: undefined,
  userInfoLoading: true,
  metafields: {},
};

const globalDataContext = createContext(defaultValue);

export const GlobalDataProvider = ({ location, children }) => {
  const [userInfoLoading, setUserInfoLoading] = useState(true);

  const [metafields, setMetafields] = useState({});

  const [globalConfig, setGlobalConfig] = useState(() => {
    const { app: appValue, shopify: shopifyValue } = getApp(location);
    const brandValue = shopifyValue.brand;
    if (brandValue === 'anker') {
      return {
        app: appValue,
        shopify: shopifyValue,
        brand: brandValue,
      };
    }
    return {};
  });

  const { app, shopify = {}, brand } = useMemo(() => globalConfig, [globalConfig]);

  const [userInfo, setUserInfo] = useState(undefined);

  useEffect(() => {
    setUserInfoLoading(true);
    const { app: appValue, shopify: shopifyValue } = getApp(location);
    const brandValue = shopifyValue.brand;
    setGlobalConfig({
      app: appValue,
      shopify: shopifyValue,
      brand: brandValue,
    });
  }, [location]);

  useEffect(() => {
    if (!app) {
      return;
    }

    if (
      !location.pathname.startsWith('/account') &&
      location.pathname !== '/email-preference/' &&
      location.pathname !== '/email-preference'
    ) {
      setUserInfoLoading(false);
      return;
    }

    if (userInfo) {
      setUserInfoLoading(false);
      return;
    }

    const { assign } = parse(location.search);
    if (assign) {
      setUserInfo({
        assign,
      });
      setUserInfoLoading(false);
      return;
    }

    const visitor = sessionStorage.getItem('visitor');
    if (visitor) {
      setUserInfo({
        isVisitor: true,
        email: visitor,
      });
      setUserInfoLoading(false);
      return;
    }
    const uesrInfoCache = getUserInfo(app);
    if (uesrInfoCache) {
      setUserInfo(uesrInfoCache);
      setUserInfoLoading(false);
      return;
    }

    makeRecaptcha('login')
      .then((recaptcha_token) => fetchProfile({ app }, { recaptcha_token }))
      .then((res) => {
        saveUserInfo(app, res.data.data);
        setUserInfo(res.data.data);
      })
      .catch((e) => {
        console.warn(e);
      })
      .finally(() => {
        setUserInfoLoading(false);
      });
  }, [app, location, userInfo]);

  useEffect(() => {
    getShopMetafields({
      filter: [
        {
          namespace: 'account',
          key: 'account_menu',
        },
        {
          namespace: 'account',
          key: 'account_help',
        },
        {
          namespace: 'account',
          key: 'order_help',
        },
        {
          namespace: 'global',
          key: 'invoice',
        },
        {
          namespace: 'account',
          key: 'customer_service',
        },
        {
          namespace: 'account',
          key: 'profile',
        },
        {
          namespace: 'account',
          key: 'policies',
        },
        {
          namespace: 'cookieConsent',
          key: 'settings',
        },
        {
          namespace: 'cookieConsent',
          key: 'disableBeforeConsent',
        },
        {
          namespace: 'footer',
          key: 'setting',
        },
      ],
    })
      .then((result) => {
        const newMetafields = {};
        result.items.forEach((item) => {
          if (!newMetafields[item.namespace]) {
            newMetafields[item.namespace] = {};
          }

          let itemValue;
          if (item.type === 'boolean') {
            if (item.value === 'true' || item.value === true) {
              itemValue = true;
            } else {
              itemValue = false;
            }
          } else {
            try {
              itemValue = JSON.parse(item.value);
            } catch (e) {
              console.error(`metafields: ${item.namespace}-${item.key} parse error`, e);
            }
          }

          if (item.namespace === 'account') {
            newMetafields[item.key] = itemValue;
          }
          newMetafields[item.namespace][item.key] = itemValue;
        });
        setMetafields(newMetafields);
      })
      .catch((e) => {
        console.error('getShopMetafields', e.message);
      });
  }, []);

  return (
    <globalDataContext.Provider
      value={{
        app,
        brand,
        shopify,
        userInfo,
        userInfoLoading,
        metafields,
      }}
    >
      {children}
    </globalDataContext.Provider>
  );
};

GlobalDataProvider.propTypes = {
  location: PropTypes.shape(),
  children: PropTypes.node,
};

export const useGlobalData = () => useContext(globalDataContext);
