/* eslint-disable no-param-reassign */
import { navigateTo, removeUserInfo } from '@/utils/helper';
import axios from 'axios';
import { parse } from 'query-string';
import { getApp } from '@/utils';

const rainbowbridge = axios.create({
  baseURL: '/rainbowbridge-api',
  headers: {},
});

function createResponseError(responseError, code) {
  const error = new Error(responseError);
  error.code = code;
  error.type = 'responseError';
  return error;
}

rainbowbridge.interceptors.request.use((config) => {
  const { app, shopify } = getApp(window.location);
  const shop = shopify.shopifyDomain || `${shopify.app}.myshopify.com`;
  const visitor = sessionStorage.getItem('visitor');
  const { assign } = parse(window.location.search);
  config.headers = {
    ...config.headers,
    'X-Application-Token': `${app}-token`,
    'X-Application-Name': `${app}-shopify`,
  };

  if (['post', 'put'].includes(config.method)) {
    config.params = {
      shop,
    };
    config.data = {
      ...config.data,
      app,
    };
  } else {
    config.params = {
      ...config.params,
      app,
      shop,
    };
  }

  if (visitor) {
    config.params.email = visitor;
  }

  if (assign) {
    config.params.assign = assign;
  }

  return config;
});

rainbowbridge.interceptors.response.use((response) => {
  if (response.status === 200 || response.status === 201) {
    const { data, msg, code } = response.data || {};

    if (code !== undefined && code !== 200 && code !== 0) {
      if (code === 403) {
        removeUserInfo(getApp(window.location).app);
        navigateTo(window.location.origin);
        throw new Error('');
      }
      throw createResponseError(msg, code);
    }

    return data;
  }

  throw new Error(response.statusText);
});

export default rainbowbridge;
