import rainbowbridge from './rainbowbridge';

// eslint-disable-next-line import/prefer-default-export
export function getShopMetafields({ filter }) {
  return rainbowbridge('/uc/shop/metafield', {
    method: 'POST',
    data: {
      filter,
    },
  });
}
