import Cookie from 'js-cookie';
import loadScript from 'load-script';
import { parse, stringify, stringifyUrl } from 'query-string';
import sha256 from 'crypto-js/sha256';
import { stores } from '../../config';

export function getApp(location) {
  let currentLocation = location;
  if (!location && typeof window !== 'undefined') {
    currentLocation = window.location;
  }

  const { app } = parse(currentLocation?.search || '');

  let shopifyValue;
  const currentStore = stores.find((item) => item.app === app || item?.appAlias?.includes(app));

  if (currentStore) {
    shopifyValue = currentStore;
  } else if (currentLocation?.host) {
    const [matched] =
      currentLocation.host.match(/ankerwork|ankermake|anker|soundcore|eufy|nebula|eufylife|seenebula|mach/) || [];
    let matchedBrand = matched;
    if (matchedBrand === 'eufylife') {
      matchedBrand = 'eufy';
    } else if (matchedBrand === 'seenebula') {
      matchedBrand = 'nebula';
    }

    const language = navigator.language || 'en';
    let matchedCountry = 'us';
    if (['de', 'nl', 'fr', 'es'].includes(language)) {
      matchedCountry = language;
    } else if (language.includes('fr')) {
      matchedCountry = 'fr';
    } else if (language.includes('es')) {
      matchedCountry = 'es';
    }

    shopifyValue = stores.find((item) => {
      return item.brand === matchedBrand && item.country === matchedCountry;
    });
  }

  shopifyValue = shopifyValue || stores[0];

  const appValue = shopifyValue.app;

  return {
    app: appValue,
    shopify: shopifyValue,
  };
}

const recaptchaLoaded = () => {
  return new Promise((resolve, reject) => {
    loadScript(`https://www.recaptcha.net/recaptcha/api.js?render=${process.env.GOOGLE_RECAPTCHA_KEY}`, (err) => {
      if (!err) {
        resolve();
      } else {
        reject(err);
      }
    });
  });
};

export const makeRecaptcha = async (action) => {
  if (!window.grecaptcha) {
    await recaptchaLoaded();
  }

  return new Promise((resolve) => {
    window.grecaptcha.ready(async () => {
      window.grecaptcha.execute(process.env.GOOGLE_RECAPTCHA_KEY, { action }).then((recaptcha) => {
        resolve(recaptcha);
      });
    });
  });
};

// 获取广告影响代码方法
export const getAdCookie = (location = {}) => {
  const { search } = location;
  const { ref, source, redirect } = parse(search);
  const { app } = getApp(location);

  let registerSource = window.location.origin;
  const registerSourceQuery = {};
  if (ref) {
    registerSourceQuery.ref = ref;
  }

  if (Cookie.get('ref_ads')) {
    const utmQuery = parse(`?${Cookie.get('ref_ads')}`);

    if (utmQuery.utm_source) {
      registerSourceQuery.utm_source = utmQuery.utm_source;
    }

    if (utmQuery.utm_medium || utmQuery.utm_media) {
      registerSourceQuery.utm_medium = utmQuery.utm_media || utmQuery.utm_medium;
    }
  }

  if (source) {
    registerSource = source;
  } else if (redirect) {
    registerSource = redirect;
  } else {
    registerSourceQuery.app = app;
  }

  return {
    app,
    fbuy_ref_code: Cookie.get('fbuy_ref_code'),
    affiliate: Cookie.get('affiliate'),
    ref: Cookie.get('ref_ads'),
    inviter_code: Cookie.get('inviter_code'),
    register_source: stringifyUrl({
      url: registerSource,
      query: registerSourceQuery,
    }),
    deals_type: Cookie.get('deal'),
    transfer_id: Cookie.get('transfer_id'),
  };
};

export function getGoogleAuthURL({ location }) {
  return `https://accounts.google.com/o/oauth2/auth?${Object.entries({
    redirect_uri: encodeURIComponent(`${location.origin}/auth/google/callback`),
    response_type: 'code',
    scope: 'email',
    client_id: process.env.GOOGLE_CLIENT_ID,
    state: encodeURIComponent(
      JSON.stringify({
        app: getApp(location).app,
        ...parse(location.search.slice(1)),
        callback: `${location.origin}/auth/google/callback`,
      })
    ),
    nonce: Date.now(),
  })
    .map(([key, value]) => `${key}=${value}`)
    .join('&')}`;
}

export function detectMobile() {
  if (typeof window === 'undefined') {
    return false;
  }
  return window.innerWidth <= 1024;
}

export const fromEu = (shopify) => {
  const eu_countries = process.env.EU_COUNTRIES.split('|');
  const country = shopify.country || 'us';
  if (eu_countries.includes(country)) {
    return true;
  }
  return false;
};

export function getLoginUrl(location, query = {}) {
  const parsedQuery = parse(location.search);
  const { app } = getApp(location);

  const { redirect, ...otherQuery } = query;

  const queryString = stringify({
    redirect: redirect || parsedQuery.redirect || `${location.origin}/account?app=${parsedQuery.app || app}`,
    ...otherQuery,
    app,
    _: Date.now(),
  });

  return `/login${queryString ? `?${queryString}` : ''}`;
}

export function getAccountLogoutUrl(location) {
  const queryString = stringify({
    ...parse(location.search),
    app: getApp(location).app,
    _: Date.now(),
  });

  return `/account/logout${queryString ? `?${queryString}` : ''}`;
}

function hex(buffer) {
  let digest = '';
  const view = new DataView(buffer);
  for (let i = 0; i < view.byteLength; i += 4) {
    // We use getUint32 to reduce the number of iterations (notice the `i += 4`)
    const value = view.getUint32(i);
    // toString(16) will transform the integer into the corresponding hex string
    // but will remove any initial "0"
    const stringValue = value.toString(16);
    // One Uint32 element is 4 bytes or 8 hex chars (it would also work with 4
    // chars for Uint16 and 2 chars for Uint8)
    const padding = '00000000';
    const paddedValue = (padding + stringValue).slice(-padding.length);
    digest += paddedValue;
  }

  return digest;
}

export const parseDomain = (url) => {
  if (!url) return '';
  let str = url;
  if (str.indexOf('://') !== -1) str = str.substr(str.indexOf('://') + 3);
  const topLevel = [
    'com',
    'net',
    'org',
    'gov',
    'edu',
    'mil',
    'biz',
    'name',
    'info',
    'mobi',
    'pro',
    'travel',
    'museum',
    'int',
    'areo',
    'post',
    'rec',
  ];
  const domains = str.split('.');
  if (domains.length <= 1) return str;
  if (!domains[domains.length - 1]) return str;
  let result = '';
  topLevel.some((v) => {
    if (domains[domains.length - 1] === v) {
      result = `${domains[domains.length - 2]}.${domains[domains.length - 1]}`;
      return true;
    }
    if (domains[domains.length - 2] === v) {
      result = `${domains[domains.length - 3]}.${domains[domains.length - 2]}`;
      return true;
    }
    return false;
  });

  if (!result) {
    result = `${domains[domains.length - 3]}.${domains[domains.length - 2]}.${domains[domains.length - 1]}`;
  }
  return result;
};

export function setHashedEmail(email) {
  const { app } = getApp(window.location);
  const domain = parseDomain(window.location.host);
  const hashedEmailKey = `${app}-hashed-email`;

  const value = sha256(email).toString();

  document.cookie = `${hashedEmailKey}=${value};domain=${domain};expires=${new Date(
    Date.now() + 30 * 24 * 60 * 60 * 1000
  )};`;

  if (window.dataLayer) {
    window.dataLayer.push({
      user_hashed_email: value,
    });
  }
}

export function createURL(str = '') {
  try {
    if (str.startsWith('http')) {
      return new URL(str).href;
    }

    if (str.startsWith('/')) {
      return new URL(origin + str).href;
    }

    return new URL(`${origin}/${str}`).href;
  } catch (e) {
    return '';
  }
}

export function passwordValidate(password = '') {
  // Password must be 8-20 characters, must contain uppercase and lowercase letters, numbers, and special characters. Allowed symbols: !@#$%^&*()_-|[{]}/?.>,';
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-|[{\]}/?.>,'])([A-Za-z\d!@#$%^&*()_\-|[{\]}/?.>,']{8,20})$/;
  return regex.test(password);
}
